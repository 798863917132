@import url(https://rsms.me/inter/inter.css);
/* Give images some breathing room */
.article-content table img {
  margin: 1rem;
  width: calc(100% - 2rem);
}

/* Base text alignment for article content */
.article-content p {
  text-align: justify;
  -moz-text-align-last: left;
       text-align-last: left;
}

/* Mostly for preview mode, show white background for articles */
.pagedjs_article-body_page {
  background: white;
}

/*
 * Force each page of article content (except the first page with the title) to
 * full page height. This is mainly needed to trigger `column-fill: auto` in
 * Chrome.
 */
.pagedjs_article-body_page:not(.pagedjs_article-body_first_page)
  .article-content {
  height: calc(
    var(--pagedjs-pagebox-height) - var(--pagedjs-margin-top) -
      var(--pagedjs-margin-bottom)
  );
}

/*
 * Don't text-indent in the paragraph is a continued paragraph from a previous
 * fragment.
 */
.article-content p[data-split-from] {
  text-indent: 0 !important;
}

/* Base in-article header styling */
.article-content h2 {
  line-height: 1.25;
  font-weight: bold;
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-indent: 0;
}

.article-content h3 {
  line-height: 1.25;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  text-indent: 0;
}

.article-content h4 {
  line-height: 1.25;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  text-indent: 0;
}

/*
 * Ensure images scale themselves down to show their entire contents, and that
 * they never take up more than 1/4 of the page height (which ends up making
 * text flow a big problem)
*/
.article-content img {
  -o-object-fit: contain;
     object-fit: contain;
  width: auto;
  max-height: 2.8125in; /* 11.25in * 25% */
}

/* Image captions */
.article-content figcaption {
  font-size: 9px;
  font-family: 'Inter var';
  text-indent: 0;
  margin: -0.5rem 1rem 1rem;
  opacity: 0.5;
  text-align: left;
}

/* Base pull quote styling */
.article-content blockquote {
  text-indent: 0;
  border-left: 4px solid #b3b3b3;
  padding: 10px 10px 10px 20px;
  margin: 14px 0;
  line-height: 1.5;
  text-align: left;
  font-style: italic;
  /*
   * Normal line height of 1.6 reduced to 1.5 for blockquotes means we can make
   * the font size 1.6/1.5, or 1.06667, times bigger
   */
  font-size: 1.0666em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  opacity: 0.8;
}

.article-content ul {
  list-style: disc;
  padding-left: 2rem;
  text-indent: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.article-content ul + ul {
  padding-top: 0;
  margin-top: -0.5rem;
}

.article-content li {
  padding: 0.1rem 0;
}

.article-content li:empty {
  display: none;
}

.article-content hr {
  margin: 0.75rem 0;
}

/* Nudge the offset toc-column around on left vs. right pages */
.pagedjs_left_page .toc-column {
  margin-right: 33%;
  margin-left: auto;
}
.pagedjs_right_page .toc-column {
  margin-left: 33%;
}
.pagedjs_left_page .toc-caption {
  right: 0;
  text-align: right;
}
.pagedjs_right_page .toc-caption {
  left: 0;
}

